export default {
  'tab.title.home': 'Home',
  'tab.title.productCenter': 'Product Center',
  'tab.title.productScheme': 'Product Categories',
  'tab.title.nordic': 'Nordic Module',
  'tab.title.dialog': 'Dialog Module',
  'tab.title.lora': 'LORA Module',
  'tab.title.cooperation': 'Cooperation',
  'tab.title.aboutUs': 'About Us',
  'tab.title.shop': 'Shop',
  'tab.title.forum': 'Forum',

  'home.more': 'More',
  'home.productCenter': 'Product Center',
  'home.companyIntroduce': 'Company Introduce',
  'home.productFeature': 'Product Feature',
  'home.cooperationScheme': 'Cooperation Scheme',
  'home.serviceFeatures': 'Service Features',
  'home.customer': 'Customer',
  'home.hotline': 'Hotline',
  'home.email': 'Message',
  'home.email.send': 'Send Message',
  'home.rights': 'All rights reserved',
  'home.email.subject': 'Subject',
  'home.email.content': 'Content',
  'home.email.contact': 'Contact',
  'home.email.captcha': 'Captcha',
  'home.please.input': 'Please Input ',
  'home.email.invalid': 'Please input the correct email address',
  'home.email.send.success': 'Send Success',
  'home.email.send.failed': 'Send Failed, Please try again later',

  'aboutUs.companyCulture': 'Company Culture',
  'aboutUs.contactUs': 'Contact Us',
  'aboutUs.phone': 'Phone',
  'aboutUs.email': 'Email',
  'aboutUs.address': 'Address',

  'product.data.dowload': 'Dowload',

  'product.chips.name': 'Module model',
  'product.chips.type': 'Module type',
  'product.chips.chip': 'Inside SOC chip',
  'product.chips.size': 'Size(mm)',
  'product.chips.bleVersion': 'BLE version',
  'product.chips.sleepElectricity': 'Sleep current',
  'product.chips.distance': 'Transmission distance(m)',
  'product.chips.operating': 'Operating',
  'product.chips.more': 'More',
  'product.chips.detail': 'Details',
  'product.chips.antenna': 'Antenna',
  'product.chips.storage': 'RAM',
  'product.chips.f': 'Working frequency',
  'product.chips.voltageMin': 'Minimum power supply',
  'product.chips.voltageMax': 'Maximum power supply',
  'product.chips.txAndRx': 'RF TX/RX peak current',
  'product.chips.temperatureMin': 'Minimum operate temperature',
  'product.chips.temperatureMax': 'Maximum operate temperature',
  'product.chips.powerMax': 'Maximum RF TX power',
  'product.chips.sensitivity': 'Receive sensitivity',
  'product.chips.package': 'Package',
  'product.chips.pinCount': 'GPIOs',
  'product.chips.support': 'Evaluation board support',
  'product.chips.feature': 'Features',
  'product.chips.application': 'Applications',
  'product.chips.note': 'Other',
  'product.chips.download': 'Download',
  'product.chips.detail.title': 'Details',

  'product.dialogs.detail': 'Details',
  'product.dialogs.name': 'Module model',
  'product.dialogs.type': 'Module type',
  'product.dialogs.chip': 'Inside SOC chip',
  'product.dialogs.size': 'Size(mm)',
  'product.dialogs.bleVersion': 'BLE version',
  'product.dialogs.sleepElectricity': 'Sleep current',
  'product.dialogs.storage': 'RAM',
  'product.dialogs.distance': 'Transmission distance(m)',
  'product.dialogs.operating': 'Operating',
  'product.dialogs.more': 'More',
  'product.dialogs.voltageMin': 'Minimum power supply',
  'product.dialogs.voltageMax': 'Maximum power supply',
  'product.dialogs.txAndRx': 'RF TX/RX peak current',
  'product.dialogs.temperatureMin': 'Minimum Operate temperature',
  'product.dialogs.temperatureMax': 'Maximum Operate temperature',
  'product.dialogs.f': 'Working frequency',
  'product.dialogs.powerMax': 'Maximum RF TX power',
  'product.dialogs.sensitivity': 'Receive sensitivity',
  'product.dialogs.antenna': 'Antenna',
  'product.dialogs.package': 'Package',
  'product.dialogs.feature': 'Features',
  'product.dialogs.application': 'Applications',
  'product.dialogs.pinCount': 'GPIOs',
  'product.dialogs.note': 'Other',
  'product.dialogs.support': 'Evaluation board support',
  'product.dialogs.download': 'Download',
  'product.dialogs.detail.title': 'Details',

  'public.resource.download': 'Resource Download',

  'company.qr.app': 'Download APK',
  'company.qr.shop': 'Follow The Latest News',
}
