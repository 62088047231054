import Vue from 'vue'
import VueRouter from 'vue-router'
import DefaultLayout from '@/layout/DefaultLayout'
import PublicResourceLayout from '@/layout/PublicResourceLayout'

Vue.use(VueRouter)

const constantRoutes = [
  {
    path: '/',
    component: DefaultLayout,
    redirect: '/welcome',
    children: [
      {
        path: 'welcome',
        name: '网站首页',
        component: () =>
          import(/* webpackChunkName: "welcome" */ '@/views/Welcome.vue'),
      },
    ],
  },
  {
    path: '/product',
    component: DefaultLayout,
    redirect: '/product/small-module',
    children: [
      {
        path: 'small-module',
        name: '产品中心',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/views/ProductList.vue'),
      },
      {
        path: 'nordic',
        name: 'Nordic方案',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/views/ProductChipsList.vue'
          ),
      },
      {
        path: 'dialog',
        name: 'Dialog方案',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/views/ProductDialogList.vue'
          ),
      },
      {
        path: 'lora',
        name: 'LORA模组',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '@/views/ProductLoraList.vue'
          ),
      },
      {
        path: 'small-module/detail/:id',
        name: '产品详情',
        component: () =>
          import(/* webpackChunkName: "product" */ '@/views/ProductDetail.vue'),
      },
    ],
  },
  {
    path: '/about-us',
    component: DefaultLayout,
    redirect: '/about-us/company',
    children: [
      {
        path: 'company',
        name: '关于我们',
        component: () =>
          import(/* webpackChunkName: "aboutUs" */ '@/views/AboutUs.vue'),
      },
      {
        path: 'cooperation',
        name: '合作方案',
        component: () =>
          import(/* webpackChunkName: "aboutUs" */ '@/views/Cooperation.vue'),
      },
    ],
  },
  {
    path: '/public/resource',
    component: PublicResourceLayout,
    redirect: '/welcome',
    children: [
      {
        path: '/public/resource/:id',
        name: '公共资源',
        component: () =>
          import(
            /* webpackChunkName: "public" */ '@/views/PublicResourceDetail.vue'
          ),
      },
    ],
    hidden: true,
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    hidden: true,
  },

  { path: '*', redirect: '/welcome', hidden: true },
]

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  })

const router = createRouter()

router.beforeEach((to, from, next) => {
  if (
    /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
      navigator.userAgent
    )
  ) {
    window.location.href = 'http://m.tshjdz.com/#' + to.path
  }
  next()
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
