export default {
  'tab.title.home': '网站首页',
  'tab.title.productCenter': '产品中心',
  'tab.title.productScheme': '产品分类',
  'tab.title.nordic': 'Nordic方案',
  'tab.title.dialog': 'Dialog方案',
  'tab.title.lora': 'LORA模组',
  'tab.title.cooperation': '合作案例',
  'tab.title.aboutUs': '关于我们',
  'tab.title.shop': '在线购买',
  'tab.title.forum': '技术论坛',

  'home.more': '更多',
  'home.productCenter': '产品中心',
  'home.companyIntroduce': '公司介绍',
  'home.productFeature': '产品特色',
  'home.cooperationScheme': '合作方案',
  'home.serviceFeatures': '服务特色',
  'home.customer': '在线客服',
  'home.hotline': '咨询热线',
  'home.email': '留言',
  'home.email.send': '发送留言',
  'home.rights': '版权所有',
  'home.email.subject': '主题',
  'home.email.content': '内容',
  'home.email.contact': '联系方式',
  'home.email.captcha': '验证码',
  'home.please.input': '请输入',
  'home.email.invalid': '请输入正确的邮箱',
  'home.email.send.success': '发送成功',
  'home.email.send.failed': '发送失败，请稍后再试',

  'aboutUs.companyCulture': '公司文化',
  'aboutUs.contactUs': '联系我们',
  'aboutUs.phone': '电话',
  'aboutUs.email': '邮箱',
  'aboutUs.address': '地址',

  'product.data.dowload': '资料下载',

  'product.chips.name': '模组型号',
  'product.chips.type': '模组类型',
  'product.chips.chip': '内置芯片',
  'product.chips.size': '尺寸(mm)',
  'product.chips.bleVersion': '蓝牙版本',
  'product.chips.sleepElectricity': '休眠工作电流',
  'product.chips.distance': '传输距离(m)',
  'product.chips.operating': '操作',
  'product.chips.more': '更多',
  'product.chips.detail': '详细信息',
  'product.chips.antenna': '天线',
  'product.chips.storage': '内存',
  'product.chips.f': '工作评率',
  'product.chips.voltageMin': '最小供电',
  'product.chips.voltageMax': '最大供电',
  'product.chips.txAndRx': '射频TX/RX峰值电流',
  'product.chips.temperatureMin': '最低工作温度',
  'product.chips.temperatureMax': '最高工作温度',
  'product.chips.powerMax': '最大发射功率',
  'product.chips.sensitivity': '接收灵敏度',
  'product.chips.package': '封装',
  'product.chips.pinCount': '对外提供的引脚IO数',
  'product.chips.support': '评估板支持',
  'product.chips.feature': '产品特点',
  'product.chips.application': '产品应用',
  'product.chips.note': '备注',
  'product.chips.download': '资料下载',
  'product.chips.detail.title': '详细信息',

  'product.dialogs.name': '模组型号',
  'product.dialogs.type': '模组类型',
  'product.dialogs.chip': '内置芯片',
  'product.dialogs.size': '尺寸(mm)',
  'product.dialogs.bleVersion': '蓝牙版本',
  'product.dialogs.sleepElectricity': '休眠工作电流',
  'product.dialogs.storage': '内存',
  'product.dialogs.distance': '传输距离(m)',
  'product.dialogs.operating': '操作',
  'product.dialogs.more': '更多',
  'product.dialogs.detail': '详细信息',
  'product.dialogs.voltageMin': '最小供电',
  'product.dialogs.voltageMax': '最大供电',
  'product.dialogs.txAndRx': '射频TX/RX峰值电流',
  'product.dialogs.temperatureMin': '最低工作温度',
  'product.dialogs.temperatureMax': '最高工作温度',
  'product.dialogs.f': '工作评率',
  'product.dialogs.powerMax': '最大发射功率',
  'product.dialogs.sensitivity': '接收灵敏度',
  'product.dialogs.antenna': '天线',
  'product.dialogs.package': '封装',
  'product.dialogs.feature': '产品特点',
  'product.dialogs.application': '产品应用',
  'product.dialogs.pinCount': '对外提供的引脚IO数',
  'product.dialogs.note': '备注',
  'product.dialogs.support': '评估板支持',
  'product.dialogs.download': '资料下载',
  'product.dialogs.detail.title': '详细信息',

  'public.resource.download': '资源下载',

  'company.qr.app': '下载APK',
  'company.qr.shop': '关注最新动态',
}
