import request from '@/utils/request'

// 发送邮件
export function sendEmailApi(data) {
  return request({
    url: '/website/home/mail/send',
    method: 'post',
    data,
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaEmailImage',

    method: 'get',
    timeout: 20000,
  })
}
