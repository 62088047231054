<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import en_US from 'ant-design-vue/es/locale/en_US'
import zh_CN from 'ant-design-vue/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
export default {
  data() {
    return {
      locale: zh_CN,
    }
  },
  // 监听路由
  watch: {
    '$route.query.locale': function (val) {
      this.locale = val === 'enUS' ? en_US : zh_CN
      moment.locale(val === 'enUS' ? 'en' : 'zh_cn')
    },
  },
  methods: {},
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

.ant-menu-submenu-popup {
  .ant-menu-sub {
    background: #1890ff;
  }
}
</style>
